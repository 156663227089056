.Menu{
    position: sticky;
    top: 0;
    z-index: 10;
}

.p-highlight > a{
    color: black !important;
    border-color: black !important;
}

.p-tabmenu {
    text-align: center;
}

.p-tabmenu-nav,.p-tabmenuitem > a {
    background-color: #cc7e85 !important;
    justify-content: center;
    display: flex;
}

.p-menuitem > a > svg,.p-menubar-button,.p-menuitem > a > span{
    color: white !important;
}

.p-menubar, .p-menubar > a, .p-menubar > ul {
    background-color: #cc7e85 !important;
}

[class="p-tabmenuitem"] > a > span,[class="p-tabmenuitem"] > a > svg {
    color: white;
}
svg {
    margin-right: 10px;
}


