h1{
    margin-top: 0px;
}

.LinkGrid{
    width: 100%;
    height: 100%;
    text-align: center;
}

.avatar{
    min-height: 20vh;
    min-width: 20vh;
}


.logos{
    max-height: 5vh;
    max-width: 7vh;
    margin-right: 10px;
}

.logos:hover{
    max-height: 10vh;
}

.button{
    min-width: 300px;
    margin: 10px !important;
    background-color: #cc7e85 !important;
    border: 0 !important;
}

.button:hover{
    min-width: 350px;
}